import {ru} from "date-fns/locale";
import type {Locale} from "date-fns/locale";
import { format as formatDateFNS, parse } from 'date-fns'
/**
 * Проверяет, истекла ли дата.
 * @param {string} dateString - Строка с датой
 * @param {string} [inputFormat] - Формат входной даты (например, 'dd-MM-yyyy'). Если не указан, используется ISO 8601
 * @returns {boolean} - Возвращает true, если дата истекла, иначе false
 */
export function isDateExpired(dateString: string, inputFormat?: string) {
    if(!dateString) return true;
    
    let date: Date;
    
    if (inputFormat) {
        try {
            date = parse(dateString, inputFormat, new Date());
        } catch (error) {
            console.error('Error parsing date with format:', error);
            return true; // В случае ошибки парсинга считаем дату истекшей
        }
    } else {
        date = new Date(dateString);
    }

    if (isNaN(date.getTime())) {
        console.error('Invalid date string');
        return true; // В случае невалидной даты считаем её истекшей
    }

    // Получаем текущую дату
    const now = new Date();

    // Сравниваем даты
    return date < now;
}

export function formatDate(
    dateString: string,
    format: string = 'd MMM yyyy HH:mm', // Формат по умолчанию
    inputFormat?: string, // Формат входной даты
): string | null {
    let date: Date;

    if (inputFormat) {
        try {
            date = parse(dateString, inputFormat, new Date());
        } catch (error) {
            console.error('Error parsing date with format:', error);
            return null;
        }
    } else {
        date = new Date(dateString);
    }

    if (isNaN(date.getTime())) {
        console.error('Invalid date string');
        return null;
    }

    try {
        return formatDateFNS(date, format, { locale: ru });
    } catch (error) {
        console.error('Error formatting date:', error);
        return null;
    }
}

export function formatTimestamp(
    timestamp: number,
    pattern: string = 'd MMM yyyy HH:mm',
    locale: Locale = ru
): string | null {
    const date = new Date(timestamp * 1000);
    return isNaN(date.getTime()) ? null : formatDateFNS(date, pattern, { locale });
}

export function formatSwimtime(ms: number): string {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    const milliseconds = Math.floor((ms % 1000) / 10);

    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${String(milliseconds).padStart(2, '0')}`;
}